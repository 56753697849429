import { ComponentType, ElementRef, forwardRef, Ref } from 'react';

import { BaseFormControlFieldProps, useFormControl } from './FormControl';

type FormControlFieldProps<FieldProps extends BaseFormControlFieldProps> = {
  Field: ComponentType<FieldProps>;
} & FieldProps;

const FormControlField = <FieldProps extends BaseFormControlFieldProps>(
  { Field, ...props }: FormControlFieldProps<FieldProps>,
  ref?: Ref<ElementRef<ComponentType<FieldProps>>>,
) => {
  const { id, isError } = useFormControl();

  return <Field id={id} isError={isError} ref={ref} {...(props as unknown as FieldProps)} />;
};

export default forwardRef(FormControlField) as <FieldProps extends BaseFormControlFieldProps>(
  props: FormControlFieldProps<FieldProps>,
) => ReturnType<typeof FormControlField>;
