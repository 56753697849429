'use client';

import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';

import cls from 'classnames';
import classNames from 'classnames';

export interface Step {
  label: string;
  body: ReactNode;
}

export interface StepperProps {
  activeStep?: number;
  disabled?: boolean;
  steps: Step[];
  nextButton?: boolean;
  prevButton?: boolean;
  orientation?: 'horizontal' | 'vertical';
  canSkiped?: boolean;
  isValid?: boolean;
  onChangeStep?: (step: number) => boolean;
  lastStepButtonName?: string;
  lastStepAction?: () => boolean;
  bodyClassName?: string;
  setActiveStep?: (step: number) => void;
  complitedStep?: number;
}

const Stepper = (
  {
    disabled = false,
    nextButton = true,
    prevButton = true,
    steps = [],
    orientation = 'horizontal',
    canSkiped = true,
    onChangeStep = () => true,
    isValid = true,
    lastStepButtonName = 'Save',
    lastStepAction = () => false,
    activeStep = 0,
    bodyClassName,
    setActiveStep,
    complitedStep = 0,
  }: StepperProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(activeStep);
  const [isError, setIsError] = useState<boolean>(false);
  const lastStep = useMemo(() => steps.length - 1, [steps]);
  const disabledStepsButton = disabled || !canSkiped;

  const handleChangeStep = (step: number) => {
    const isValidChangeStep = onChangeStep(step);

    if (step < currentStep) {
      setIsError(false);

      if (setActiveStep) {
        setActiveStep(step);
      }

      return setCurrentStep(step);
    }

    if (isValidChangeStep) {
      setIsError(false);

      if (setActiveStep) {
        setActiveStep(step);
      }

      return setCurrentStep(step);
    }

    return setIsError(true);
  };

  const isDisabled = (index: number) => {
    if (complitedStep && disabledStepsButton) {
      return index >= complitedStep;
    }

    if (disabledStepsButton) {
      return currentStep < index;
    }

    return false;
  };

  useEffect(() => {
    setIsError(!isValid);
  }, [isValid]);

  useEffect(() => {
    setCurrentStep(activeStep);
  }, [activeStep]);

  return (
    <div
      ref={ref}
      className={`stepper w-100 flex items-center justify-center ${
        orientation === 'horizontal' ? 'flex-col' : 'flex-row'
      }`}
    >
      <div
        className={`stepper--header mb-12  ${
          orientation === 'horizontal' ? 'hidden flex-row md:flex' : 'flex flex-col'
        }`}
      >
        {steps.map((el, index) => (
          <button
            type="button"
            disabled={isDisabled(index)}
            key={`${index}_${el.label}`}
            onClick={() => handleChangeStep(index)}
            className={cls(
              'relative flex w-20 cursor-pointer flex-col after:absolute after:bg-old-dark-40 after:content-[""] last:after:content-none',
              {
                'ml-24 after:left-20 after:top-6 after:h-px after:w-24 first:ml-0':
                  orientation === 'horizontal',
                'mb-24 after:left-10 after:top-20 after:h-20 after:w-px':
                  orientation !== 'horizontal',
              },
            )}
          >
            <div
              className={cls(
                'mx-auto',
                'mb-3',
                'h-12',
                'w-12',
                'rounded-full',
                'border',
                'text-center',
                'text-xs',
                'font-bold',
                'leading-[3rem]',
                'shadow-main',
                {
                  'bg-neutral-50': index === currentStep,
                  'border-old-primary': index === currentStep,
                  'text-old-primary': index === currentStep,
                  'bg-old-primary': index < currentStep,
                  'border-none': index < currentStep,
                  /* TODO: Refactor when Icons component will be ready  */
                  'after:content-[url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNC43NzEzIDBMNC45NTg4NSAxMC4zOTY0TDEuMjI4NjggNi40NDQyNkwwIDcuNzQ2MDZMNC45NTg4NSAxM0wxNiAxLjMwMThMMTQuNzcxMyAwWiIgZmlsbD0id2hpdGUiLz4NCjwvc3ZnPg0K")]':
                    index < currentStep,
                  'bg-old-primary-10': index > currentStep,
                  'text-old-primary-40': index > currentStep,
                  'border-old-primary-40': index > currentStep,
                },
              )}
            >
              {index >= currentStep ? index + 1 : ''}
            </div>
            <span
              className={cls('w-full text-center text-xs font-medium', {
                'text-old-dark': index === currentStep,
                'text-old-dark-40': index === currentStep,
              })}
            >
              {el.label}
            </span>
          </button>
        ))}
      </div>
      <div
        className={cls({
          'stepper--header mb-12 w-full md:hidden': orientation === 'horizontal',
          hidden: orientation === 'vertical',
        })}
      >
        <div className="h-2.5 w-full rounded-full bg-old-dark-40">
          <div
            className="h-2.5 rounded-full bg-old-primary"
            style={{ width: `${(100 / steps.length) * (currentStep + 1)}%` }}
          ></div>
        </div>
      </div>
      <div
        className={classNames(
          'stepper--body flex flex-col justify-center align-middle',
          bodyClassName,
        )}
      >
        <div className="stepper--content">{steps[currentStep]?.body}</div>
        <div className="stepper--footer flex flex-row flex-wrap justify-center align-middle">
          {prevButton && (
            <button
              type="button"
              className={`stepper--button-prev m-5 h-10 w-40 rounded-full bg-old-primary font-medium text-neutral-50 hover:bg-old-primary-60 disabled:bg-old-primary-25`}
              disabled={currentStep === 0 || disabled}
              onClick={() => handleChangeStep(currentStep - 1)}
            >
              Back
            </button>
          )}
          {nextButton && currentStep !== lastStep && (
            <button
              type="button"
              className={cls(
                'stepper--button-next m-5 h-10 w-40 rounded-full bg-old-primary font-medium text-neutral-50 hover:bg-old-primary-60 disabled:bg-old-primary-25',
                { 'bg-old-secondary hover:bg-old-secondary-60': isError },
              )}
              disabled={disabled}
              onClick={() => handleChangeStep(currentStep + 1)}
            >
              Next
            </button>
          )}
          {nextButton && currentStep === lastStep && (
            <button
              type="button"
              className={cls(
                'stepper--button-next m-5 h-10 w-40 rounded-full bg-old-primary font-medium text-neutral-50 hover:bg-old-primary-60 disabled:bg-old-primary-25',
                { 'bg-old-secondary hover:bg-old-secondary-60': isError },
              )}
              disabled={disabled}
              onClick={() => lastStepAction()}
            >
              {lastStepButtonName}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Stepper);
