export { default as CompactWidget } from './CompactWidget';

export * from './CompactWidget';

export { default as SmallWidget } from './SmallWidget';

export * from './SmallWidget';

export { default as MainWidget } from './MainWidget';

export * from './MainWidget';
