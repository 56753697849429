'use client';

import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';

import classNames from 'classnames';

import DashedBorder from '@uikit/components/DashedBorder/DashedBorder';
import UploadCloud from '@uikit/icons/UploadCloud';

export type FileDropzoneProps = DropzoneOptions & {
  disabled?: boolean;
  isError?: boolean;
  className?: string;
  id?: string;
  name?: string;
  inputRef?: Ref<HTMLInputElement>;
} & Pick<ComponentPropsWithoutRef<'div'>, 'onBlur'>;

type FileDropzoneState = 'default' | 'active' | 'error' | 'disabled';

const getState = ({
  isError,
  disabled,
  isDragActive,
  isFocused,
}: Pick<FileDropzoneProps, 'isError' | 'disabled'> &
  Pick<DropzoneState, 'isFocused' | 'isDragActive'>): FileDropzoneState => {
  if (disabled) {
    return 'disabled';
  } else if (isError) {
    return 'error';
  } else if (isDragActive || isFocused) {
    return 'active';
  }

  return 'default';
};

const FileDropzone = (
  {
    id,
    className,
    isError,
    inputRef,
    disabled,
    name,
    onBlur,
    ...dropzoneOptions
  }: FileDropzoneProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    disabled,
    ...dropzoneOptions,
  });
  const state = getState({ isError, disabled, isDragActive, isFocused });

  return (
    <div
      className={classNames(
        'relative flex min-h-[150px] select-none flex-col items-center justify-center overflow-hidden rounded-lg p-6',
        'focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-primary',
        'group',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className,
      )}
      {...getRootProps({
        ref,
        onBlur,
      })}
    >
      <DashedBorder
        borderRadius={8}
        strokeWidth={1}
        strokeDasharray="4, 4"
        strokeLinecap="round"
        strokeDashoffset={6}
        className={classNames(
          'pointer-events-none absolute inset-0 transition-colors',
          (
            {
              active: 'bg-primary-50 text-primary',
              disabled: 'text-neutral-100',
              default: 'text-primary-300 group-hover:text-primary',
              error: 'text-red-600',
            } as const
          )[state],
        )}
      />
      <input id={id} data-qa="upload-file-input" {...getInputProps({ name, ref: inputRef })} />
      <UploadCloud
        className={classNames(
          'relative icon-xl mb-2',
          (
            {
              disabled: 'text-neutral-300',
              error: 'text-red-600',
              default: 'text-neutral-600',
              active: 'text-neutral-600',
            } as const
          )[state],
        )}
      />
      <p
        className={classNames(
          'relative flex flex-col gap-1 text-center text-base font-medium leading-5',
          disabled ? 'text-neutral-300' : 'text-neutral-400',
        )}
      >
        Drag files to upload
        <span className={classNames({ 'text-primary': !disabled })}>
          Choose a file or drag it here.
        </span>
      </p>
    </div>
  );
};

export default forwardRef(FileDropzone);
