import { ComponentPropsWithoutRef } from 'react';

import cls from 'classnames';

export interface ProgressProps extends ComponentPropsWithoutRef<'div'> {
  value: number;
  variant?: 'primary' | 'secondary';
}

const Progress = ({ value = 0, variant = 'primary' }: ProgressProps) => {
  return (
    <div
      className={cls('w-full relative h-1 rounded overflow-hidden', {
        'bg-primary-300': variant === 'primary',
        'bg-secondary-300': variant === 'secondary',
      })}
    >
      <div
        className={cls('absolute left-0 top-0 bottom-0', {
          'bg-primary': variant === 'primary',
          'bg-secondary': variant === 'secondary',
        })}
        style={{ width: `${value}%` }}
      />
    </div>
  );
};

export default Progress;
