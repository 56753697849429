import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import Close from '@uikit/icons/Close';
import Delete from '@uikit/icons/Delete';
import File from '@uikit/icons/File';

export interface FileItemProps {
  id: string;
  title: string;
  subtitle?: string;
  thumbnail?: string | JSX.Element;
  isError?: boolean;
  isClickable?: boolean;
}

export interface FileUploadListProps extends ComponentPropsWithoutRef<'ul'> {
  onItemRemove?: (id: string) => void;
  onItemClick?: (id: string) => void;
  items: FileItemProps[];
}

interface FileUploadListItemProps {
  onItemRemove?: (id: string) => void;
  onItemClick?: (id: string) => void;
  item: FileItemProps;
}

const FileUploadListItem = ({ onItemClick, onItemRemove, item }: FileUploadListItemProps) => {
  const { id, title, thumbnail, subtitle, isError, isClickable } = item;

  const handleClick = () => {
    onItemClick?.(id);
  };

  const handleRemove = () => {
    onItemRemove?.(id);
  };

  return (
    <li key={id} className="flex gap-2 border-b border-primary-50 pb-2">
      <div
        className={classNames(
          'flex h-12 w-12 flex-shrink-0 items-center justify-center overflow-hidden',
          isClickable && 'cursor-pointer',
        )}
        onClick={handleClick}
      >
        {thumbnail &&
          (typeof thumbnail === 'string' ? (
            <img
              src={thumbnail}
              className="h-full w-full rounded-md border border-primary-50"
              alt={title}
            />
          ) : (
            thumbnail
          ))}

        {!thumbnail && (
          <File
            className={classNames('icon-[100%]', isError ? 'text-red-600' : 'text-neutral-200 ')}
          />
        )}
      </div>
      <div className="flex grow flex-col gap-0.5 text-sm leading-4">
        <p
          className={classNames(
            isError ? 'text-red-600' : 'text-neutral-600',
            'line-clamp-1',
            isClickable && 'cursor-pointer',
          )}
          onClick={handleClick}
        >
          {title}
        </p>
        {subtitle && <p className="line-clamp-1 font-light text-neutral-400">{subtitle}</p>}
      </div>

      <div className="flex flex-shrink-0 items-center justify-center">
        <button className="text-red-600" onClick={handleRemove}>
          <span className="icon">{isError ? <Close /> : <Delete />}</span>
        </button>
      </div>
    </li>
  );
};

export const FileUploadList = ({ items, onItemRemove, onItemClick }: FileUploadListProps) => {
  return (
    <ul className="flex flex-col gap-2">
      {items.map((item) => {
        return (
          <FileUploadListItem
            key={item.id}
            item={item}
            onItemRemove={onItemRemove}
            onItemClick={onItemClick}
          />
        );
      })}
    </ul>
  );
};

export default FileUploadList;
