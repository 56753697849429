'use client';

import { forwardRef, KeyboardEventHandler, Ref, useMemo, useState } from 'react';
import { ClassNamesConfig, GroupBase, MultiValue } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import classNames from 'classnames';

import { Plus } from '@uikit/icons/Plus';

import { BaseFormControlFieldProps } from '../FormControl';

export type { GroupBase, Props as SelectProps } from 'react-select';

export const getSelectClassNames = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): ClassNamesConfig<Option, IsMulti, Group> => {
  return {
    container: () => {
      return classNames('min-w-[150px]');
    },
    control: ({ isFocused, isDisabled, selectProps: { isReadonly, isError, shape } }) => {
      let stateClassName = 'hover:text-field-hover';

      if (isReadonly) {
        stateClassName = 'text-field-readonly';
      } else if (isDisabled) {
        stateClassName = 'text-field-disabled';
      } else if (isFocused) {
        stateClassName = 'text-field-focus';
      } else if (isError) {
        stateClassName = 'text-field-error';
      }

      return classNames(
        'text-field-base py-[0.4375rem] !min-h-[2.5rem]',
        shape === 'pill' && 'text-field-shape-pill',
        !isDisabled && !isReadonly && '!cursor-pointer',
        stateClassName,
      );
    },
    indicatorsContainer: ({ isDisabled, selectProps }) => {
      return classNames((isDisabled || selectProps.isReadonly) && '!hidden', 'items-start');
    },
    clearIndicator: () => {
      return classNames('self-start w-6 h-6 flex items-center justify-center');
    },
    indicatorSeparator: () => {
      return 'hidden';
    },
    valueContainer: () => {
      return classNames('gap-1 flex flex-wrap');
    },
    dropdownIndicator: ({ selectProps }) => {
      return classNames(
        'text-primary !transition-transform origin-center',
        selectProps.menuIsOpen && 'rotate-180',
      );
    },
    input: () => {
      return classNames('min-w-[50px] h-6');
    },
    menu: () => {
      return classNames('mt-2 rounded bg-neutral-50 shadow-popover');
    },
    menuList: () => {
      return classNames('!max-h-[180px] py-2 divide-y divide-primary-100');
    },
    option: ({ isSelected, isFocused }) => {
      let bg = 'hover:bg-primary-100';

      if (isSelected) {
        bg = 'bg-primary-300';
      } else if (isFocused) {
        bg = 'bg-primary-100';
      }

      return classNames(
        '!flex items-center min-h-[2.625rem] px-4 py-1 !cursor-pointer !text-xs',
        bg,
      );
    },
    multiValue: () => {
      return classNames(
        'h-6 flex-row-reverse rounded-full items-center px-2 border border-primary-300 gap-0.5',
      );
    },
    multiValueRemove: () => {
      return classNames('');
    },
    noOptionsMessage: () => {
      return classNames('flex items-center justify-center text-xs min-h-[40px]');
    },
    placeholder: () => {
      return classNames('text-field-placeholder');
    },
  };
};

type TagFieldProps = Pick<CreatableProps<string, false, GroupBase<string>>, 'placeholder'> &
  BaseFormControlFieldProps & {
    onChange?: (value: readonly string[]) => void;
    defaultValue?: string[];
    value?: string[];
    readOnly?: boolean;
  };

const TagField = (
  {
    value: controlledValue,
    defaultValue,
    disabled,
    readOnly,
    onChange,
    id,
    ...props
  }: TagFieldProps,
  ref: Ref<HTMLInputElement>,
) => {
  const [inputValue, setInputValue] = useState('');
  const [uncontrolledValue, setUncontrolledValue] = useState<MultiValue<string>>(
    defaultValue || [],
  );
  const isControlledValue = typeof controlledValue !== 'undefined';
  const value = isControlledValue ? controlledValue : uncontrolledValue;
  const selectClassNames: ClassNamesConfig<string, true, GroupBase<string>> = useMemo(
    () => getSelectClassNames(),
    [],
  );

  const addValue = (currentValue: MultiValue<string>, tag: string) => {
    if (onChange) {
      onChange([...currentValue, tag.trim()]);
    }

    if (!isControlledValue) {
      setUncontrolledValue((prev) => [...prev, tag.trim()]);
    }

    setInputValue('');
  };

  const selectComponents: Partial<SelectComponents<string, true, GroupBase<string>>> =
    useMemo(() => {
      return {
        DropdownIndicator: null,
        IndicatorsContainer: ({ children, selectProps }) => {
          return (
            <div className="self-start flex gap-1">
              <button
                type="button"
                className={classNames(
                  'icon-button button-solid button-color-primary icon-button-sm',
                  {
                    invisible: !selectProps.inputValue.trim(),
                  },
                )}
                onClick={() =>
                  addValue(selectProps.value as MultiValue<string>, selectProps.inputValue)
                }
              >
                <Plus className="icon-2xs" />
              </button>
              {children}
            </div>
          );
        },
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
        addValue(value, inputValue);

        event.preventDefault();
        break;

      case 'Tab':
        setInputValue('');

        event.preventDefault();
        break;
    }
  };

  const handleChange = (newValue: readonly string[]) => {
    if (onChange) {
      onChange(newValue);
    }

    if (!isControlledValue) {
      setUncontrolledValue(newValue);
    }
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <CreatableSelect<string, true, GroupBase<string>>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      menuIsOpen={false}
      value={value}
      inputValue={inputValue}
      className=""
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      isMulti
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option}
      isClearable
      inputId={id}
      components={selectComponents}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      isReadonly={readOnly}
      isDisabled={disabled || readOnly}
      classNames={selectClassNames}
      unstyled
      {...props}
    />
  );
};

export default forwardRef(TagField);
