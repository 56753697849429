import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';

import classNames from 'classnames';

import TableRow from './TableRow';

interface TableHeaderProps extends ComponentPropsWithoutRef<'div'> {
  children?: ReactNode;
  headings?: {
    label: ReactNode;
    key: string;
  }[];
  rows?: Array<Record<string, ReactNode | (() => ReactNode)>> | undefined;
  styleCells?: CSSProperties;
  variant?: 'default' | 'rounded' | 'accordion';
}

const TableBody = ({
  children,
  rows,
  headings,
  styleCells,
  className,
  variant,
  ...props
}: TableHeaderProps) => {
  if (children) {
    return (
      <div {...props} className={classNames(className)}>
        {children}
      </div>
    );
  }

  const rowResult = rows?.map((row) => {
    return headings?.map(({ key }) => {
      if (row[key]) {
        return row[key];
      }

      return '-';
    });
  });

  return (
    <div>
      {rowResult?.length &&
        rowResult.map((row, index) => (
          <TableRow
            key={`tablebody_${index}`}
            row={row}
            styleCells={styleCells}
            variant={variant}
          />
        ))}
    </div>
  );
};

export default TableBody;
