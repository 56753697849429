import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';

import classNames from 'classnames';

interface TableHeaderProps extends ComponentPropsWithoutRef<'div'> {
  headings?:
    | {
        label: ReactNode;
        key: string;
      }[]
    | undefined;
  styleCells?: CSSProperties | undefined;
  variant?: 'default' | 'rounded' | 'accordion';
  isStickyHeader?: boolean;
}

export const TableHeader = ({
  variant = 'default',
  headings,
  styleCells,
  className,
  isStickyHeader,
  children,
  ...props
}: TableHeaderProps) => {
  if (children) {
    return (
      <div {...props} className={classNames(className)}>
        {children}
      </div>
    );
  }

  return (
    <div
      {...props}
      className={classNames(
        'w-full grid grid-flow-col mb-3 gap-4 px-4',
        {
          'bg-primary-100 text-neutral-600': variant === 'default',
          'rounded-lg bg-primary-100 text-neutral-300 px-2':
            variant === 'accordion' || variant === 'rounded',
          'sticky top-0 z-10': isStickyHeader,
        },
        className,
      )}
      style={styleCells}
    >
      {headings?.map((heading, index) => (
        <div
          key={`${heading.key}_${index}`}
          className="whitespace-nowrap text-[15px] py-3 text-left"
        >
          {heading.label}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
