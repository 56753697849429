import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';

import classNames from 'classnames';

interface TableRowProps extends ComponentPropsWithoutRef<'div'> {
  row: Array<ReactNode | (() => ReactNode)> | undefined;
  styleCells: CSSProperties | undefined;
  variant?: 'default' | 'rounded' | 'accordion';
}

const TableRow = ({ row, styleCells, variant }: TableRowProps) => {
  return (
    <div
      className={classNames('grid grid-flow-col gap-4 px-4', {
        'border border-primary-300 rounded-xl my-4': variant === 'rounded',
      })}
      style={styleCells}
    >
      {row?.map((el, index) => (
        <div
          key={`leaderboard_${index}`}
          className="overflow-hidden min-w-0 whitespace-nowrap py-3 text-left font-normal text-[15px]"
        >
          {typeof el === 'function' ? el() : el}
        </div>
      ))}
    </div>
  );
};

export default TableRow;
